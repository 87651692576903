import { css, tw } from 'twind/css';

import { HeadersContent } from 'groq/global-types';
import { getComponentForContent } from 'utils/getComponentForContent';
import {
  ComponentWithFilters as GqlComponentWithFilters,
  Maybe,
} from '__generated__/graphql';
import { ComponentWithFilters } from '__generated__/graphql-sanity';
import { mapSanityHeadingsToObject } from 'utils/mapSanityHeadingsToObject';

type SanityContentSlotPropsType = {
  items?: Maybe<Array<Maybe<GqlComponentWithFilters | ComponentWithFilters>>>;
  slotId: string;
  className?: string;
  documentId: string | null | undefined;
  // The fullscreen option is used to add negative margins to negate the
  // page level padding on the first and full bleed last elements
  fullscreen?: boolean;
  headersContent?: HeadersContent;
};

export const SanityContentSlot = (props: SanityContentSlotPropsType) => {
  if (!props.items || props.items.length === 0) return null;
  const headersObject = mapSanityHeadingsToObject(
    props.headersContent,
    props.items as GqlComponentWithFilters[]
  );

  return (
    <div
      data-test-id="sanity-content-slot"
      className={tw(
        'flex flex-col gap-6 md:gap-8 lg:gap-10',
        props.fullscreen &&
          css`
            & > *:first-child {
              [data-bleed='true'] {
                @apply mobile:-mt-6 tablet:-mt-8 desktop:-mt-10;
              }
            }
            & > *:last-child {
              [data-bleed='true'] {
                @apply mobile:-mb-6 tablet:-mb-8 desktop:-mb-10;
              }
            }
          `,
        css`
          & > [data-type='FullBleedHero'] + [data-type='FullBleedHero'] {
            @apply mobile:-mt-6 tablet:-mt-8 desktop:-mt-10;
          }
        `,
        props.className
      )}
      data-document={props.documentId}
    >
      {props.items.map((...item) =>
        getComponentForContent(...item, props.slotId, headersObject)
      )}
    </div>
  );
};
