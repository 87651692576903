import React, { useEffect } from 'react';
import { UseQueryState } from 'urql';
import { useRouter } from 'next/router';

import { useGA4ScrollEvents } from 'hooks/useGA4ScrollEvents';
import { LoadingSpinner } from 'ui/components/LoadingSpinner';
import { NoSearchResults } from 'ui/components/NoSearchResults';
import Error from 'pages/_error';
import usePageview from 'hooks/usePageview';
import { AnalyticsPageType } from 'types/analyticTypes';
import {
  useBloomreachPageviewPixel,
  BloomreachDataType,
} from 'hooks/useBloomreachPixel';
import { useFocusVisible } from 'hooks/a11y/useFocusVisible';
import { useEmarsysWebExtend } from 'hooks/useEmarsysWebExtend';
import { useCookieSettings } from 'hooks/useCookieSettings';
import { getPageType } from 'utils/analytics';

import { getIsDesktop } from '../../utils/media';

interface PageProps {
  query?: UseQueryState<any> | UseQueryState<any>[];
  loadingMessage?: string;
  pageViewPageType?: AnalyticsPageType;
  bloomreachData?: BloomreachDataType;
  emarsysData?: { skipTracking?: boolean };
  children?: React.ReactNode;
}

export const PageLoadingSpinner = (props: { loadingMessage?: string }) => (
  <section className="flex w-full h-[80vh]">
    <div className="m-auto">
      <LoadingSpinner className="w-full" dataTestId="page-loading-spinner" />
      {props.loadingMessage && (
        <p className="p-2 text-center text-base text-puma-black-300">
          {props.loadingMessage}
        </p>
      )}
    </div>
  </section>
);

export const Page = ({
  query = { fetching: false, error: undefined, stale: false },
  emarsysData = { skipTracking: true },
  loadingMessage,
  pageViewPageType,
  bloomreachData,
  children,
}: PageProps) => {
  const fetching = Array.isArray(query)
    ? query.some(x => x.fetching)
    : query.fetching;

  const { showCookieSettings } = useCookieSettings();
  const isDesktop = getIsDesktop();
  useGA4ScrollEvents();

  const router = useRouter();
  const UK_STOREFINDER_PATH = '/uk/en/storefinder';
  const JP_STOREFINDER_PATH = '/jp/ja/storefinder';
  const storeFinderPaths = new Set([UK_STOREFINDER_PATH, JP_STOREFINDER_PATH]);
  const isPdp = getPageType(router.pathname) === 'pdp';

  useFocusVisible();
  // Register a page view once the page data has loaded
  usePageview(fetching, pageViewPageType);
  useBloomreachPageviewPixel(fetching, bloomreachData);
  const { omitTrackingCommand } = useEmarsysWebExtend();

  useEffect(() => {
    if (emarsysData?.skipTracking) {
      omitTrackingCommand();
    }
  }, [emarsysData?.skipTracking, omitTrackingCommand]);

  let queriesWithErrors: UseQueryState<any>[] = [];
  if (Array.isArray(query)) {
    queriesWithErrors = query.filter(x => !!x.error);
  } else if (query.error) {
    queriesWithErrors = [query];
  }

  const hasNotFoundError = queriesWithErrors.some(q =>
    q.error?.graphQLErrors.some(
      e => e.extensions && e.extensions.code === 'NOT_FOUND'
    )
  );

  const hasData = Array.isArray(query) ? query.some(x => x.data) : query.data;

  if (fetching) {
    return <PageLoadingSpinner loadingMessage={loadingMessage} />;
  }

  if (hasNotFoundError) {
    return <NoSearchResults />;
  }

  if (queriesWithErrors.length > 0 && !hasData) {
    return <Error statusCode={500} />;
  }

  let position = 'relative';
  if (showCookieSettings && !isDesktop) {
    position = 'fixed';
  }

  const isUkOrJapanStorefinder = storeFinderPaths.has(router.asPath);

  const padding = isUkOrJapanStorefinder
    ? 'mobile:p-6 tablet:p-8 desktop:p-12'
    : 'mobile:p-4 tablet:p-6 desktop:p-8';

  return (
    <div
      className={`box-border flex flex-col  ${
        !isPdp && `mobile:space-y-4 tablet:space-y-6 desktop:space-y-8`
      }  ${padding} max-w-screen-3xl mx-auto font-display ${position}`}
    >
      {children}
    </div>
  );
};
