import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { REGION_INFO } from 'utils/regionBasedConstants';
import { Maybe } from '__generated__/graphql';
import { useSiteConfig } from 'hooks/useSiteConfig';
import {
  getMappedCountryCode,
  getUrlFromLocale,
  removeLocalePrefix,
} from 'utils/locale';
import { parseHtml } from 'utils/html';
export const PageHead: React.FC<{
  title: Maybe<string> | undefined;
  description?: Maybe<string> | undefined;
  openGraphDescription?: Maybe<string> | undefined;
  openGraphImageUrl?: Maybe<string> | undefined;
  openGraphTitle?: Maybe<string> | undefined;
  openGraphType?: Maybe<string> | undefined;
  openGraphVideoUrl?: Maybe<string> | undefined;
  twitterDescription?: Maybe<string> | undefined;
  twitterImageUrl?: Maybe<string> | undefined;
  twitterTitle?: Maybe<string> | undefined;
  noIndex?: Maybe<boolean> | undefined;
}> = props => {
  const { asPath } = useRouter();
  const { host } = useSiteConfig();
  const { supportedLocales, locale, countryCode } = useSiteConfig();

  const hostIndexBlackList = [
    'web.development.cybercat.',
    'web.staging.cybercat.',
    '.production.cybercat.',
    'web.nitro.development.xetic.puma.com',
    'web.nitro.staging.xetic.puma.com',
    'pre-web.nitro.development.xetic.puma.com',
    '.nitro.production.xetic.puma.com',
  ];

  const isIndexableHost = hostIndexBlackList.some(baseUrl =>
    host.includes(baseUrl)
  );

  const path = removeLocalePrefix(asPath, locale);
  const robotsContent =
    props.noIndex || isIndexableHost ? 'noindex, nofollow' : 'index, follow';

  const sanitizedDescription = parseHtml(props.description!);
  const mappedCountryCode =
    getMappedCountryCode(countryCode) || countryCode.toLowerCase();
  const countryConstants = REGION_INFO[mappedCountryCode];

  const sanitizedOgDescription = props.openGraphDescription
    ? parseHtml(props.openGraphDescription)?.textContent
    : '';
  const sanitizedTwitterDescription = props.twitterDescription
    ? parseHtml(props.twitterDescription)?.textContent
    : '';

  const description = sanitizedDescription?.textContent || '';

  return (
    <Head>
      {countryConstants && countryConstants.name && (
        <meta
          property="og:site_name"
          content={`PUMA ${countryConstants.name}`}
        />
      )}
      {props.openGraphVideoUrl && (
        <meta property="og:video" content={props.openGraphVideoUrl} />
      )}
      {props.openGraphType && (
        <meta property="og:type" content={props.openGraphType} />
      )}
      {props.openGraphImageUrl && (
        <meta property="og:image" content={props.openGraphImageUrl} />
      )}
      {props.twitterImageUrl && (
        <meta property="twitter:image" content={props.twitterImageUrl} />
      )}
      {props.title && (
        <>
          <title>{props.title}</title>
          <meta
            property="twitter:title"
            content={props.twitterTitle ? props.twitterTitle : props.title}
          />
          <meta
            property="og:title"
            content={props.openGraphTitle ? props.openGraphTitle : props.title}
          />
        </>
      )}
      {props.description && (
        <>
          <meta name="description" content={description} />
          <meta
            property="og:description"
            content={
              sanitizedOgDescription ? sanitizedOgDescription : description
            }
          />
          <meta
            property="twitter:description"
            content={
              sanitizedTwitterDescription
                ? sanitizedTwitterDescription
                : description
            }
          />
        </>
      )}
      <meta name="robots" content={robotsContent} />
      {/* Sites with multiple languages (US & CA) */}
      {supportedLocales.length > 1 &&
        supportedLocales.map((locale: string) => {
          const [lang, country] = locale.split('-');
          const hrefLang = `${lang}-${country.toUpperCase()}`;
          const localeUrl = getUrlFromLocale(locale);
          return (
            <link
              key={hrefLang}
              rel="alternate"
              hrefLang={hrefLang}
              href={`https://${country}.puma.com${localeUrl}${path}`}
            />
          );
        })}
      {props.children}
    </Head>
  );
};
