import { useEffect, useState } from 'react';

import { useWindowScroll } from './useWindowScroll';
import { useWindowSize } from './useWindowSize';

const getScrollOnPagePercentage = ({
  windowHeight,
  scrollY,
}: {
  windowHeight: number;
  scrollY: number;
}): number => {
  const documentHeight = document.body.scrollHeight;
  const scrollPercentage = (scrollY / (documentHeight - windowHeight)) * 100;

  return Math.min(Math.max(Math.round(scrollPercentage), 0), 100);
};

export const useScrollPagePercentage = () => {
  const [{ y: scrollY }] = useWindowScroll();
  const { height: windowHeight } = useWindowSize();

  const [scrollPagePercentage, setScrollPagePercentage] = useState(0);

  useEffect(() => {
    if (scrollY !== null && windowHeight !== null) {
      setScrollPagePercentage(
        getScrollOnPagePercentage({
          windowHeight,
          scrollY,
        })
      );
    }
  }, [scrollY, windowHeight]);

  return scrollPagePercentage;
};

// usage: `const scrollPagePercentage = useScrollPagePercentage();`
