import { LocaleString } from 'groq/shared/LocaleString';
import { useLiveChat } from 'hooks/useLiveChat';
import { useTranslate } from 'hooks/useTranslations';
import { CustomerSupport } from '__generated__/graphql';
import { useGroqQuery } from 'hooks/useGroqQuery';

export type CustomerSupportBannerContent = {
  chatHours?: string | null;
  email?: string | null;
  phone?: string | null;
  phoneHours?: string | null;
  title?: string | null;
};

declare module 'groq/GroqOperationNames' {
  interface GroqOperationNames {
    CustomerSupportBanner: CustomerSupportBannerContent;
  }
}
function useContentQuery() {
  return useGroqQuery({
    operationName: 'CustomerSupportBanner',
    query: `
      *[_type == "CustomerSupport" && country == $country][0] {
        ${LocaleString('title')},
        email,
        phone,
        phoneHours,
        chatHours,
      }
    `,
  });
}

export const CustomerSupportBanner = () => {
  const {
    liveChatEnabled,
    LiveChatScript,
    LiveChatToggleWrapper,
    openLiveChatWindow,
  } = useLiveChat();
  const t = useTranslate();
  const [contentQuery] = useContentQuery();

  if (
    contentQuery.fetching ||
    contentQuery.error ||
    !contentQuery.data ||
    !Object.values(contentQuery.data.content || {}).some(x => x)
  ) {
    return null;
  }

  const { title, email, phone, phoneHours, chatHours } = contentQuery.data
    .content as CustomerSupport;

  return (
    <>
      <LiveChatScript />
      <div
        className="p-8 sm:p-12 bg-puma-black-800 lg:flex-row mb-6! sm:mb-8! lg:mb-12! grid grid-cols-1 lg:grid-cols-2 w-full col-span-full"
        data-test-id="customer-support-banner"
      >
        <h3
          className="w-full text-2xl lg:text-3xl font-bold pb-6 lg:pb-0"
          data-test-id="customer-support-banner-title"
        >
          {title}
        </h3>
        <div
          className={`
          grid gap-2
          children:(
            grid grid-cols-1 md:grid-cols-3 pb-4 md:pb-0 items-center
            children:(
              first-child:(
                pb-2 md:pb-0
              )
              last-child:(
                col-span-2
              )
            )
          )
        `}
        >
          {email && (
            <div data-test-id="customer-support-banner-email">
              <p>{t('email')}:</p>
              <a
                className="font-bold uppercase px-0 text-sm link-underline"
                href={`mailto:${email}`}
              >
                {email}
              </a>
            </div>
          )}
          {phone && (
            <div data-test-id="customer-support-banner-phone">
              <p>{t('phone')}:</p>
              <p>
                <a
                  className="relative font-bold uppercase px-0 text-sm link-underline"
                  href={`tel:${phone}`}
                >
                  {phone}
                </a>
                {phoneHours && (
                  <span className="text-xs uppercase pl-0 md:pl-2 pt-2 md:pt-0 block md:inline">
                    {phoneHours}
                  </span>
                )}
              </p>
            </div>
          )}
          {liveChatEnabled && chatHours && (
            <LiveChatToggleWrapper>
              <p>{t('liveChat')}:</p>
              <p>
                <button
                  type="button"
                  data-test-id="live-chat-button"
                  className="relative font-bold uppercase px-0 text-sm link-underline"
                  onClick={() => openLiveChatWindow()}
                >
                  {t('startChat')}
                </button>
                {chatHours && (
                  <span className="text-xs uppercase pl-0 md:pl-2 pt-2 md:pt-0 block md:inline">
                    {chatHours}
                  </span>
                )}
              </p>
            </LiveChatToggleWrapper>
          )}
        </div>
      </div>
    </>
  );
};
