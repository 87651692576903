import { useCallback, useEffect, useState } from 'react';

import { event, AnalyticsEvents } from 'utils/analytics';
import {
  SCROLL_GA4_EVENT_PERCENTAGES,
  SCROLL_GA4_EVENT_PERCENTAGE_STRING,
  SCROLL_GA4_EVENT_PERCENTAGE_TYPE,
} from 'utils/constants';

import { useOnRouterChangeStart } from './useOnRouterChangeStart';
import { usePageEventsContext } from './usePageEventsContext';
import { useScrollPagePercentage } from './useScrollPagePercentage';

export const useGA4ScrollEvents = () => {
  const scrollPagePercentage = useScrollPagePercentage();
  const { pageviewEventHasFired, eventHasFired, setEventFired } =
    usePageEventsContext();

  const [currentPercentage, setCurrentPercentage] = useState<
    SCROLL_GA4_EVENT_PERCENTAGE_TYPE | undefined
  >(undefined);

  useOnRouterChangeStart(
    useCallback(() => {
      if (pageviewEventHasFired) {
        // when page start to change -> reset current percentage
        // this avoid problems of fire again the events previously cleaned on pageEventContext
        setCurrentPercentage(undefined);
      }
    }, [pageviewEventHasFired])
  );

  useEffect(() => {
    if (pageviewEventHasFired) {
      // get the "current" percentage from the percentages (25, 50, 75, 100)
      const percentageToFire = [...SCROLL_GA4_EVENT_PERCENTAGES]
        .reverse()
        .find(percentage =>
          scrollPagePercentage >= parseInt(percentage) ? percentage : null
        );

      setCurrentPercentage(percentageToFire);
    }
  }, [scrollPagePercentage, pageviewEventHasFired]);

  useEffect(() => {
    if (pageviewEventHasFired) {
      // only call function when the current percentage changes (undefined, 25, 50, 75, 100)
      if (currentPercentage) {
        firePreviousAndCurrentGA4ScrollEvents({
          percentage: currentPercentage,
          eventHasFired,
          setEventFired,
        });
      }
    }
  }, [currentPercentage, eventHasFired, setEventFired, pageviewEventHasFired]);
};

// declare here to allow to be used from another files - ex. load-more-button
export const firePreviousAndCurrentGA4ScrollEvents = ({
  percentage,
  eventHasFired,
  setEventFired,
}: {
  percentage: SCROLL_GA4_EVENT_PERCENTAGE_TYPE;
  eventHasFired: (event: string) => boolean;
  setEventFired: (event: string) => void;
}) => {
  const ctxFunctions = { setEventFired, eventHasFired };

  switch (percentage) {
    case '25':
      checkAndFireGA4ScrollEvent({ percentage: '25', ...ctxFunctions });
      break;
    case '50':
      checkAndFireGA4ScrollEvent({ percentage: '25', ...ctxFunctions });
      checkAndFireGA4ScrollEvent({ percentage: '50', ...ctxFunctions });
      break;
    case '75':
      checkAndFireGA4ScrollEvent({ percentage: '25', ...ctxFunctions });
      checkAndFireGA4ScrollEvent({ percentage: '50', ...ctxFunctions });
      checkAndFireGA4ScrollEvent({ percentage: '75', ...ctxFunctions });
      break;
    case '100':
      checkAndFireGA4ScrollEvent({ percentage: '25', ...ctxFunctions });
      checkAndFireGA4ScrollEvent({ percentage: '50', ...ctxFunctions });
      checkAndFireGA4ScrollEvent({ percentage: '75', ...ctxFunctions });
      checkAndFireGA4ScrollEvent({ percentage: '100', ...ctxFunctions });
      break;
  }
};

export const checkAndFireGA4ScrollEvent = ({
  eventHasFired,
  setEventFired,
  percentage,
}: {
  percentage: SCROLL_GA4_EVENT_PERCENTAGE_TYPE;
  setEventFired: (event: string) => void;
  eventHasFired: (event: string) => boolean;
}) => {
  if (!eventHasFired(SCROLL_GA4_EVENT_PERCENTAGE_STRING[percentage])) {
    event(AnalyticsEvents.GA4_CustomEvent, {
      event_name: AnalyticsEvents.SCROLL,
      event_params: { percent_scrolled: percentage },
    });
    setEventFired(SCROLL_GA4_EVENT_PERCENTAGE_STRING[percentage]);
  }
};
