type RegionInfoType = {
  [key: string]: {
    name: string;
    legalName: string;
    url: string;
    logo: string;
    image: string;
    streetAddress: string;
    addressLocality: string;
    postalCode: string;
    addressCountry: string;
    telephone: string;
    email: string;
    areaServed: string;
    alternateName: string[] | string;
  };
};

export const REGION_INFO: RegionInfoType = {
  ca: {
    name: 'Canada',
    legalName: 'Puma Canada Inc.',
    url: 'https://ca.puma.com/',
    logo: 'https://ca.puma.com/assets/logo-puma-black.png',
    image: 'https://ca.puma.com/assets/logo-puma-black.png',
    streetAddress: '165 Galaxy Boulevard, Suite 201',
    addressLocality: 'Toronto',
    postalCode: 'M9W 0C8',
    addressCountry: 'CA',
    telephone: '1-888-565-PUMA',
    email: 'customerservice.ca@puma.com',
    areaServed: 'Canada',
    alternateName: 'PUMA CA',
  },
  in: {
    name: 'India',
    legalName: 'PUMA Sports India Private Limited',
    url: 'https://in.puma.com/',
    logo: 'https://in.puma.com/assets/logo-puma-black.png',
    image: 'https://in.puma.com/assets/logo-puma-black.png',
    streetAddress: 'Ground floor No 496, Mahadevapura Main Road',
    addressLocality: 'Bengaluru, Karnataka',
    postalCode: '560016',
    addressCountry: 'IN',
    telephone: '080-3535 3535',
    email: 'customercareindia@puma.com',
    areaServed: 'IN',
    alternateName: 'PUMA IN',
  },
  jp: {
    name: '日本',
    legalName: 'プーマ ジャパン株式会社',
    url: 'https://jp.puma.com/',
    logo: 'https://jp.puma.com/assets/logo-puma-black.png',
    image: 'https://jp.puma.com/assets/logo-puma-black.png',
    streetAddress: '大崎2-1-1 Think Park Tower 18F',
    addressLocality: '東京都品川区',
    postalCode: '1416018',
    addressCountry: 'JP',
    telephone: '046-212-2633',
    email: 'cs-jp@puma.com',
    areaServed: '日本',
    alternateName: 'PUMA JP',
  },
  uk: {
    name: 'UK',
    legalName: 'PUMA UNITED KINGDOM LTD',
    url: 'https://uk.puma.com/',
    logo: 'https://uk.puma.com/assets/logo-puma-black.png',
    image: 'https://uk.puma.com/assets/logo-puma-black.png',
    streetAddress: '22 Upper Ground',
    addressLocality: 'London',
    postalCode: 'SE1 9AE',
    addressCountry: 'GB',
    telephone: '+44 (0) 203 326 54 81',
    email: 'service-uk@puma.com',
    areaServed: 'United Kingdom',
    alternateName: 'PUMA United Kingdom',
  },
  us: {
    name: 'US',
    legalName: 'PUMA North America Inc.',
    url: 'https://us.puma.com/',
    logo: 'https://us.puma.com/assets/logo-puma-black.png',
    image: 'https://us.puma.com/assets/logo-puma-black.png',
    streetAddress: '455 Grand Union Boulevard',
    addressLocality: 'Sommerville, MA',
    postalCode: '02145',
    addressCountry: 'US',
    telephone: '(978) 698-1000',
    email: 'customerservice.us@puma.com',
    areaServed: 'USA',
    alternateName: ['PUMA USA', 'PUMA United States'],
  },
};
