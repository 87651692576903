import { SlotsGroqQuery } from 'groq/ContentSlots';
import { LocaleSeo, LocaleSeoType } from 'groq/shared/LocaleSeo';
import { ComponentWithFilters, Maybe } from '__generated__/graphql-sanity';
import { LocaleString } from 'groq/shared/LocaleString';

export type NotFoundPageContent = {
  _id: string;
  _type: 'Error404';
  seo: LocaleSeoType;
  heading: Maybe<string>;
  content: Maybe<Array<Maybe<ComponentWithFilters>>>;
};

declare module 'groq/GroqOperationNames' {
  interface GroqOperationNames {
    NotFoundPage: NotFoundPageContent;
  }
}

export const NotFoundPageQuery = `
  *[_type == "Error404" && country == $country][0] {
    _id,
    _type,
    ${LocaleSeo('seo')},
    ${LocaleString('heading')},
    content[] ${SlotsGroqQuery()},
  }
`;
