import { isServer } from 'utils/constants';
import { Maybe } from '__generated__/graphql-sanity';

export const parseHtml = (html: string): Maybe<HTMLBodyElement> | undefined => {
  if (!isServer) {
    const parser = new window.DOMParser();
    const htmlDoc = parser.parseFromString(html, 'text/html');
    return htmlDoc.querySelector('body')!;
  }
};
