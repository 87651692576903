import * as Sentry from '@sentry/nextjs';
import { NextPage } from 'next';
import React from 'react';

import { useTranslate } from 'hooks/useTranslations';
import { PageHead } from 'ui/components/PageHead';
import { SearchForm } from 'ui/components/NoSearchResults';
import { ProductRecommender } from 'ui/content/ProductRecommender';
import { useFeature } from 'hooks/useFeature';

const ErrorPage: NextPage<{
  statusCode?: number;
  hasGetInitialPropsRun?: boolean;
  err?: any;
}> = ({ statusCode, hasGetInitialPropsRun, err }) => {
  if (!hasGetInitialPropsRun && err) {
    Sentry.captureException(err);
  }

  const t = useTranslate();
  const showSearchFormOnError = useFeature('SHOW_SEARCH_FORM_ON_ERROR');
  const showProductRecommenderOnError = useFeature(
    'SHOW_PRODUCT_RECOMMENDER_ON_ERROR'
  );

  return (
    <>
      <PageHead
        title={t('pageTitleReduced', {
          title: statusCode ? `${statusCode}` : '',
        })}
      >
        <meta name="robots" content="noindex" />
      </PageHead>
      <div className="flex flex-col items-center justify-center mx-auto max-w-4xl xl:max-w-6xl mobile:p-4 tablet:p-6 desktop:p-8">
        <div
          className="font-display flex flex-col items-center justify-center mobile:space-y-4 tablet:space-y-6 desktop:space-y-8 py-24 w-full"
          data-test-id="not-found-error-msg"
        >
          <h1
            className="font-bold text-4xl lg:text-5xl text-center"
            id="not-found-oops"
          >
            {t('notFoundOops')}
          </h1>

          {showSearchFormOnError && <SearchForm />}

          {showProductRecommenderOnError && (
            <ProductRecommender type="search-no-results-viewed-recently" />
          )}
        </div>
      </div>
    </>
  );
};

ErrorPage.getInitialProps = async ({ err, asPath }) => {
  const initialProps = {
    // Workaround for https://github.com/vercel/next.js/issues/8592
    hasGetInitialPropsRun: true,
  };
  if (err) {
    Sentry.captureException(err);

    return initialProps;
  }

  // If this point is reached, getInitialProps was called without any
  // information about what the error might be. This is unexpected and may
  // indicate a bug introduced in Next.js, so record it in Sentry
  Sentry.captureException(
    new Error(`_error.tsx getInitialProps missing data at path: ${asPath}`)
  );
  await Sentry.flush(2000);

  return initialProps;
};

export default ErrorPage;
